import React, { useEffect, useState } from "react"
import queryString from "query-string"
import { Helmet } from "react-helmet"
import { Layout, SEO, LWrap, CJumbotron, CBreadCrumb } from "../../../components/_index"
import PrivateLayout from "../../../components/_extra/private_layout"

// markup
const SubPage = ({ location }: any) => {
  const { contentId, draftKey } = queryString.parse(location.search);
  const [data, setData] = useState<any | null>(null);
  useEffect(() => {
    fetch(`https://${process.env.MICRO_CMS_SERVICE_DOMAIN}.microcms.io/api/v1/private_nihonbashi/${contentId}?draftKey=${draftKey}`, {
      headers: {
        'X-MICROCMS-API-KEY': process.env.MICRO_CMS_API_KEY || "",
      },
    })
      .then(res => res.json())
      .then(res => setData({ res }));
  }, []);

  if (data === null) {
    return null;
  }

  const auth = data.res.basicauth ? 'auth/' : ''
  return (
    <Layout>
      <SEO title="プレビュー"
        description="プレビュー"
        ogtype={"blog"}
      />
      <section style={{ marginTop: "120px" }}>
        <LWrap>
          <p>案内URL ： https://www.rph.co.jp/private/{auth}{contentId}/</p>
        </LWrap>
      </section>
      <PrivateLayout data={data.res} />
    </Layout>
  )
}


export default SubPage;
